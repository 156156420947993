import * as React from "react";
import { CallAction } from "components/call-action";
import thumbUrl from 'images/video-thumb.jpg';
import * as cls from './styles.module.css';
import * as cn from 'classnames';

export const Hero = ({
  style,
  className,
  ...props
}) => {
  return (
    <div style={style} className={cn(cls.root, className)}>
      <div className={cls.textContainer}>
        <div className={cls.tagline}>
          <div>Поможем</div>
          <div>заговорить</div>
          <div>на немецком</div>
          {/* <CallAction link="111" className={cls.callAction}/> */}
        </div>
        <div className={cls.subtitle}>курсы в сопровождении опытных преподавателей</div>
      </div>
      <img src={thumbUrl} className={cls.image} alt="video" />
    </div>
  )
}