import * as React from "react";
import * as cls from './styles.module.css';
import * as cn from 'classnames';
import { SocialLink } from "../social-link";

const cssClasses = {
  icon: cls.socialIcon,
  label: cls.socialLabel,
}

export const Footer = ({
  className,
  ...props
}) => {
  return (
    <div className={cn(cls.root, className)} {...props}>
      <SocialLink className={cls.item} cssClasses={cssClasses}
        type="whatsapp" url="tel:+79163708261" label="+7 916 370 8261" />
      <SocialLink className={cls.item} cssClasses={cssClasses}
        type="telegram" url="https://t.me/sprintschool" label="t.me/sprintschool" />
      <SocialLink className={cls.item} cssClasses={cssClasses}
        type="instagram" url="https://instagram.com/sprintschool/" label="/sprintschool" />
      {/* <SocialLink className={cls.item} cssClasses={cssClasses}
        type="vk" url="" label="vk" />
      <SocialLink className={cls.item} cssClasses={cssClasses}
        type="facebook" url="" label="facebook" />
      <SocialLink className={cls.item} cssClasses={cssClasses}
        type="tiktok" url="" label="tiktok" /> */}
    </div>
  )
}