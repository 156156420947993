import * as React from "react";
import PropTypes from 'prop-types';
import { Header } from "components/header";
import { Hero } from "components/hero";
import * as cls from './styles.module.css';
import * as cn from 'classnames';

export const TitleHeader = ({
  menu
}) => {
  return (
    <div className={cls.root}>
      <Header className={cls.header} menu={menu} />
      <Hero className={cls.hero} />
    </div>
  )
}

Header.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
}