import * as React from "react";
import * as cls from './styles.module.css';
import LogoSvg, { ReactComponent as LogoSvgCmp } from './logo.svg';

export const Logo = props => {
  return (
    <div className={cls.root}>
      <div className={cls.logo}>
        <div>Sprint</div>
        <LogoSvgCmp alt="Logo" />
        <div>School</div>
      </div>
      
      
      {/* <div className={cls.labels}>
        <div className={cls.subtitle}>
          Школа немецкого языка
        </div>
      </div> */}
    </div>
  );
};