import * as React from "react";
import PropTypes from 'prop-types';
import * as cls from './styles.module.css';
import { Link } from "gatsby";

export const Menu = props => {
  return (
    <div className={cls.root}>
      {props.menu.map(link => 
        <Link key={link[1]} className={cls.link} to={link[1]}>{link[0]}</Link>
      )}
    </div>
  )
}

Menu.defaultProps = {
  menu: []
}

Menu.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
}