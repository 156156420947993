import * as React from "react";
import PropTypes from 'prop-types';
import * as cls from "./styles.module.css";
import * as cn from 'classnames';
import { Link } from "gatsby";

import { ReactComponent as facebookUrlOutline } from './assets/outlined/facebook.svg';
import { ReactComponent as instagramUrlOutline } from './assets/outlined/instagram.svg';
import { ReactComponent as whatsappUrlOutline } from './assets/outlined/whatsapp.svg';
import { ReactComponent as telegramUrlOutline } from './assets/outlined/telegram.svg';
import { ReactComponent as vkUrlOutline } from './assets/outlined/vk.svg';
import { ReactComponent as tiktokUrlOutline } from './assets/outlined/tiktok.svg';
import { ReactComponent as facebookUrlFilled } from './assets/filled/facebook.svg';
import { ReactComponent as instagramUrlFilled } from './assets/filled/instagram.svg';
import { ReactComponent as whatsappUrlFilled } from './assets/filled/whatsapp.svg';
import { ReactComponent as telegramUrlFilled } from './assets/filled/telegram.svg';
import { ReactComponent as vkUrlFilled } from './assets/filled/vk.svg';
import { ReactComponent as tiktokUrlFilled } from './assets/filled/tiktok.svg';

const icons = {
  outlined: {
    'facebook': facebookUrlOutline,
    'instagram': instagramUrlOutline,
    'whatsapp': whatsappUrlOutline,
    'telegram': telegramUrlOutline,
    'vk': vkUrlOutline,
    'tiktok': tiktokUrlOutline,
  },
  filled: {
    'facebook': facebookUrlFilled,
    'instagram': instagramUrlFilled,
    'whatsapp': whatsappUrlFilled,
    'telegram': telegramUrlFilled,
    'vk': vkUrlFilled,
    'tiktok': tiktokUrlFilled,
  }
}

export const SocialLink = ({
    type, 
    design,
    url, 
    label, 
    className,
    cssClasses,
    ...props
}) => {
  const Icon = icons[design][type];
  const iconCss = cssClasses && cssClasses.icon ? cssClasses.icon : cls.svg;
  const content = [
    <Icon key="icon" className={iconCss} />,
    <div key="div" className={cssClasses.label}>{label}</div>
  ]
  return (
    url.startsWith('tel:') ? <a href={url} className={cn(cls.root, className)}>{content}</a> :
    <Link className={cn(cls.root, className)} {...props} to={url}>
      {content}
    </Link>
  )
}

SocialLink.propTypes = {
  type: PropTypes.oneOf(['facebook', 'instagram', 'whatsapp', 'telegram', 'vk', 'tiktok']),
  design: PropTypes.oneOf(['outlined', 'filled']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  url: PropTypes.string,
  label: PropTypes.string,
}

SocialLink.defaultProps = {
  design: 'outlined',
  size: 'medium',
  cssClasses: {}
}