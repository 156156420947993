import * as React from "react";
import * as cn from 'classnames';
import PropTypes from 'prop-types';
import * as cls from './styles.module.css';
import { Link } from "gatsby";
import { TitleHeader } from "../../../pages/title-header";
import { Header } from "components/header";
import { Footer } from "components/footer";

const menu = [
  ['Курсы', '#courses'],
  ['Преподаватели', '#tutors'],
  ['Контакты', '#contacts'],
];

export const Page = ({ 
  header,
  footer,
  className,
  children,
  ...props
}) => {
  return (
    <div className={cn(cls.root, className)} {...props}>
      {header === 'hero' ? <TitleHeader menu={menu} /> : header ?
        <Header className={cls.header} />
      : undefined}
      {children}
      <Footer />
    </div>
  )
}

Page.defaultProps = {
  header: true,
  footer: true
}

Page.propTypes = {
  header: PropTypes.oneOf([false, true, 'hero']),
  footer: PropTypes.oneOf([false, true])
}