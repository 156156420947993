import * as React from "react";
import PropTypes from 'prop-types';
import { Logo } from "../logo";
import { Menu } from "../menu";
import * as cls from "./styles.module.css";
import * as cn from "classnames";
import { Link } from "gatsby";

export const Header = ({
  className,
  menu,
  ...props
}) => {
  return (
    <div className={cn(cls.root, className)} {...props}>
      <Link to="/">
        <Logo className={cls.logo}/>
      </Link>
      <Menu menu={menu}/>
    </div>
  )
}

Header.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
}